<template>
  <div>
    <c-card class="cardClassDetailInfo" :noHeader="true">
      <template slot="card-detail">
        <div class="col-3">
          <c-label-text title="안전작업표준 작업명" :value="sop.sopName"></c-label-text>
        </div>
        <div class="col-3">
          <c-label-text title="LBLPLANT" :value="sop.plantName"></c-label-text>
        </div>
        <div class="col-3">
          <c-label-text title="단위공정" :value="sop.processName"></c-label-text>
        </div>
        <div class="col-3">
          <c-label-text title="안전작업표준 관리번호" :value="sop.sopNo"></c-label-text>
        </div>
        <div class="col-2">
          <c-label-text title="작업구분" :value="sop.workClassName"></c-label-text>
        </div>
        <div class="col-2">
          <c-label-text title="작업유형" :value="sop.workTypeName"></c-label-text>
        </div>
        <div class="col-2">
          <c-label-text title="작업형태" :value="sop.workKindName"></c-label-text>
        </div>
        <div class="col-2">
          <c-label-text title="위험등급" :value="sop.hazardGradeName"></c-label-text>
        </div>
        <div class="col-1">
          <c-label-text title="제(개)정번호" :value="sop.revisionNum"></c-label-text>
        </div>
        <div class="col-1">
          <c-label-text title="제(개)정자" :value="sop.regUserName"></c-label-text>
        </div>
        <div class="col-2">
          <c-label-text title="제(개)정일시" :value="sop.regDtStr"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      title="작업절차"
      tableId="steptable"
      topBorderClass="topcolor-lightblue"
      :columnSetting="false"
      :usePaging="false"
      :filtering="false"
      :columns="grid.columns"
      :data="sop.sopSummary"
      :merge="grid.merge"
      :isExcelDown="false"
    >
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'sop-report',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
      }),
    },
    sop: {
      type: Object,
      default: () => ({
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        sopNo: '',  // 안전작업 표준 번호
        sopName: '',  // 세부공정(작업) 명
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        applyRange: '',  // 적용범위
        purpose: '',  // 목적
        hazardGradeCd: null,  // 위험등급 1 ~ 5등급
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        workClassCd: null,  // 작업구분 : Set-up, PM, BM, Part교체, 기타
        workerName: '',  // 작업자
        managementDepts: '',  // 관리부서 복수
        managementDepts2: '',  // 관리부서 복수
        saftyShied: '',  // 안전보호구
        saftyCaution: '',  // 안전주의사항
        sopMocId: '',
        useFlag: 'Y',  // 사용여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        regUserId: '',
        chgUserId: '',
        revSelectValue: null,
        sopSteps: [], // 작업단계 목록
        revs: [], // 개정 목록
        mmEquips: [], // 설비 목록
        mmMachines: [], // 기계기구 목록
        chems: [], // 화학자재 목록
        sopSummary: [], // 요약정보
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'jobStepName' },
          { index: 1, colName: 'jobStepName' },
          { index: 2, colName: 'jobStepName' },
          { index: 3, colName: 'mdmHazardFactorsId' },
          { index: 6, colName: 'jobStepName' },
          { index: 7, colName: 'jobStepName' },
          { index: 8, colName: 'jobStepName' },
        ],
        columns: [
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업단계',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'downPath',
            field: 'downPath',
            label: '도해(사진/그림)',
            align: 'center',
            style: 'width:150px',
            type: 'img',
            sortable: false,
          },
          {
            name: 'jobStepDesc',
            field: 'jobStepDesc',
            label: '작업방법(상세설명)',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '원인',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'caution',
            field: 'caution',
            label: '안전작업방법(주의사항)',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'saftyShied',
            field: 'saftyShied',
            label: '안전보호구',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'useTool',
            field: 'useTool',
            label: '사용공구',
            align: 'left',
            type: 'html',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>