var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "안전작업표준 작업명",
                    value: _vm.sop.sopName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: { title: "LBLPLANT", value: _vm.sop.plantName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: { title: "단위공정", value: _vm.sop.processName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "안전작업표준 관리번호",
                    value: _vm.sop.sopNo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "작업구분", value: _vm.sop.workClassName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "작업유형", value: _vm.sop.workTypeName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "작업형태", value: _vm.sop.workKindName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "위험등급", value: _vm.sop.hazardGradeName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("c-label-text", {
                  attrs: { title: "제(개)정번호", value: _vm.sop.revisionNum },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("c-label-text", {
                  attrs: { title: "제(개)정자", value: _vm.sop.regUserName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "제(개)정일시", value: _vm.sop.regDtStr },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "작업절차",
          tableId: "steptable",
          topBorderClass: "topcolor-lightblue",
          columnSetting: false,
          usePaging: false,
          filtering: false,
          columns: _vm.grid.columns,
          data: _vm.sop.sopSummary,
          merge: _vm.grid.merge,
          isExcelDown: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }